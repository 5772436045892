import { createGlobalStyle } from 'styled-components';

import { media } from './media';

const theme = (key) => (props) => props.colors[key];

const GlobalStyles = createGlobalStyle`
  :root {
    --primary: ${theme('primary')};
    --primary-dark: ${theme('primary-dark')};
    --primary-light: ${theme('primary-light')};
    --primary-contrast: ${theme('primary-contrast')};
    --secondary: ${theme('secondary')};
    --secondary-dark: ${theme('secondary-dark')};
    --secondary-light: ${theme('secondary-light')};
    --secondary-contrast: ${theme('secondary-contrast')};
    --tertiary: ${theme('tertiary')};
    --tertiary-dark: ${theme('tertiary-dark')};
    --tertiary-light: ${theme('tertiary-light')};
    --tertiary-contrast: ${theme('tertiary-contrast')};
    --grey-1: ${theme('grey-1')};
    --grey-2: ${theme('grey-2')};
    --grey-3: ${theme('grey-3')};
    --grey-4: ${theme('grey-4')};
    --grey-5: ${theme('grey-5')};
    --grey-6: ${theme('grey-6')};
    --black: ${theme('black')};
    --white: ${theme('white')};
    --accent-1: ${theme('accent-1')};

    /* TODO: Refactor these variables out of the app, they're mostly unused but might have some obscure impact */
    --white-rgb: 252,251,247;
    --grey-2-rgb: 248,246,235;
    --grey-3-rgb: 228,226,212;
    --orange-dark: #9d2407;
    --orange-rgb: 210,72,33;
    --orange-light-rgb: 242,186,179;
    --yellow-dark-rgb: 183,102,27;
    --yellow-rgb: 231,152,59;
    --yellow-light-rgb: 255,214,169;
    --primary-rgb: 225,104,71;
    --primary-rgb: 31,45,38;
    --accent-1-rgb: 224,217,191;
    --accent-1-rgb: 231,181,153;
    --primary-dark-rgb: var(--primary-rgb);
    --primary-rgb: var(--primary-rgb);
    --primary-light-rgb: var(--accent-1-rgb);
    --secondary-rgb: var(--orange-rgb);
    --secondary-light-rgb: var(--orange-light-rgb);
    --tertiary-dark-rgb: var(--yellow-dark-rgb);
    --tertiary-rgb: var(--yellow-rgb);
  }

  * {
    box-sizing: border-box;
  }

  html {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    scroll-padding-top: var(--nav-height, 66px);
    scroll-behavior: smooth;
  }
  body, html {
    min-height: 100vh;
    font-family: 'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';

  }
  body {
    position: relative;
    background: var(--grey-2);
  }

  html.is-scroll-disabled-nav body {
    height: 100vh;
    overflow: hidden;
    .nav--holder, .nav--holder--retail {
      &.nav-up {
        transform: translateY(0);
      }
    }
  }
  html.is-scroll-disabled body {
    height: 100vh;
    overflow: hidden;
    main {
      position: relative;
      z-index: 40;
    }
    .btn--chat {
      opacity: 0;
    }
  }

  .nav--holder, .nav--holder--retail {
    position: fixed;
    top: 0;
    z-index: 20;
    width: 100%;
    opacity: 1;
    transition: all 0.3s linear;
    &.nav-up {
      transform: translateY(-100%);
      transition: all 0.3s linear;
    }
    &.nav-down {
      transform: translateY(0);
      transition: all 0.3s linear;
    }
    &.is-active {
      .nav--links {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .wrapper {
      position: relative;
      min-height: 100vh;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  img {
    max-width: 100%;
  }

  a {
      transition: all .15s linear;
      cursor: pointer;
      text-decoration: none;
  }

  hr {
    border-color: var(--grey-3);
  }

  .color--white {
    color: var(--white);
  }
  .color--white-7 {
    color: rgba(var(--white-rgb),0.7);
  }
  .color--primary {
    color: var(--primary);
    path {
        fill: var(--primary);
    }
  }
  .color--primary-dark {
    color: var(--primary);
    path {
        fill: var(--primary);
    }
  }
  .color--primary-dark {
    color: var(--primary-dark);
  }
  .color--primary-light {
    color: var(--accent-1);
  }
  .color--accent-1 {
    color: var(--accent-1);
  }
  .color--accent-1 {
    color: var(--accent-1);
  }
  .color--secondary {
    color: var(--secondary);
    path {
        fill: var(--secondary);
    }
  }
  .color--secondary-light {
    color: var(--secondary-light);
  }
  .color--tertiary {
    color: var(--tertiary);
    path {
        fill: var(--tertiary);
    }
  }
  .color--tertiary-light {
    color: var(--tertiary-light);
  }
  .color--grey-1 {
    color: var(--grey-1);
  }
  .color--grey-2 {
    color: var(--grey-2);
  }
  .color--grey-3 {
    color: var(--grey-3);
  }
  .color--grey-3-7 {
    color: rgba(var(--grey-3-rgb),0.7);
  }
  .color--grey-4 {
    color: var(--grey-4);
    path {
        fill: var(--grey-4);
    }
  }
  .color--grey-5 {
    color: var(--grey-5);
  }
  .color--grey-6 {
    color: var(--grey-6);
    path {
      fill: var(--primary);
    }
  }

  .border-grey-3 {
    border-color: var(--grey-3);
  }
  .border-grey-4 {
    border-color: var(--grey-4);
  }

  .bg--white {
    background-color: var(--white);
  }
  .bg--black {
    background-color: var(--black);
  }
  .bg--primary {
    background-color: var(--primary);
  }
  .bg--primary-dark {
    background-color: var(--primary);
  }
  .bg--primary-dark {
    background-color: var(--primary-dark);
  }
  .bg--secondary {
    background-color: var(--secondary);
  }
  .bg--secondary-dark {
    background-color: var(--secondary-dark);
  }
  .bg--tertiary {
    background-color: var(--tertiary);
  }
  .bg--grey-1 {
    background-color: var(--grey-1);
  }
  .bg--grey-2 {
    background-color: var(--grey-2);
  }
  .bg--grey-3 {
    background-color: var(--grey-3);
  }
  .bg--grey-4 {
    background-color: var(--grey-4);
  }
  .bg--grey-5 {
    background-color: var(--grey-5);
  }
  .bg--grey-6 {
    background-color: var(--grey-6);
  }
  .body--primary {
    background: var(--primary) !important;
  }

  .db {
    display: block;
  }

  .w-14 {
    @media ${media.lgUp} {
      width: 140px;
    }
  }
  .w-20 {
    width: 200px;
  }
  .w-25 {
    width: 250px;
  }
  .w-27 {
    width: 270px;
  }
  .w-450 {
    max-width: 450px;
  }

  .equipment {
    width: 150px;
    @media (min-width: 800px){
      width: 200px;
    }
    @media ${media.lgUp} {
      width: 270px;
    }
  }

  .p5 {
    padding-top: 6px;
  }
  .top-160 {
    top: calc(160px + 68px);
  }

  .border-top {
    border-top: 1px solid var(--grey-3);
  }

  .video--holder {
    height: 0;
    padding-bottom: 42%;
    overflow: hidden;
    video {
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      width: 100%;
      height: 100%;
    }
    &.video--holder--full {
        video {
            object-fit: cover;
        }
    }
  }

  // Modal
  .modal {
    position: fixed;
    overflow: hidden;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    background: var(--grey-2);
    z-index: 40;
    height: 100vh;
    display: flex;
    align-items: center;
    transform: translateY(-100%);
    transition: all 0.4s cubic-bezier(0.4, 0, 1, 1); // Ease In
    &.is-active {
      transform: translateY(0);
      transition: all 0.5s cubic-bezier(0, 0, 0.2, 1); // Ease Out
    }
    &.modal--video {
      transform: translateY(0);
      opacity: 0;
      visibility: hidden;
      &.is-active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .model--open {
    overflow: hidden;
    height: 100vh;
    .modal--bg {
      opacity: 1;
      visibility: visible;
    }
  }
  .modal--bg {
    position: fixed;
    z-index: 18;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    background: rgba(31,31,29, 0.08);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s linear;
    cursor: pointer;
    &.is-active {
      opacity: 1;
      visibility: visible;
    }
  }

  // Accordion
  .accordion {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s cubic-bezier(0,1,0,1);
    .bb {
      border-bottom: 1px solid var(--grey-3);
    }
    &.is-active {
      max-height: 9999px;
      transition:max-height 0.4s cubic-bezier(1,0,1,0);
    }
  }
  .accordion--trigger {
    cursor: pointer;
    svg {
      transition: all 0.3s linear;
    }
    &.is-active {
      svg {
        transform: rotate(45deg);
      }
    }
  }

  // Image Swipe Transition
  @keyframes background-intro {
    0% {
      opacity: 0;
      -webkit-transform: scaleY(0) translateY(0);
      transform: scaleY(0) translateY(0);
    }
    10% {
      opacity: 1;
    }
    50% {
      -webkit-transform: scaleY(1) translateY(0);
      transform: scaleY(1) translateY(0);
    }
    to {
      -webkit-transform: scaleY(1) translateY(-105%);
      transform: scaleY(1) translateY(-105%);
    }
  }
  @keyframes image-intro {
    0% {
      opacity: 0;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
    20% {
      opacity: 1;
    }
    to {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .bg--swipe {
    padding-top: 116%;
    @media ${media.mdUp} {
      padding-top: 60%;
    }
    @media ${media.lgUp} {
      padding-top: 130%;
    }
    &.bg--swipe--portrait {
      padding-top: 116%;
      @media ${media.mdUp} {
        padding-top: 130%;
      }
      @media ${media.lgUp} {
        padding-top: 130%;
      }
    }
    &.bg--swipe--landscape {
      padding-top: 60%;
      @media ${media.mdUp} {
        padding-top: 60%;
      }
      @media ${media.lgUp} {
        padding-top: 60%;
      }
    }
    &.bg--swipe--bg::after {
      position: absolute;
      z-index: 1;
      content: '';
      top: -80px;
      inset-inline-end: 60px;
      width: 300%;
      height: 100%;
      background-color: var(--primary);
      @media ${media.lgUp} {
        top: -8rem;
        inset-inline-end: 0;
      }
    }
  }
  .bg--swipe--wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    visibility: hidden;
    &.is-active {
      visibility: visible;
      .bg--swipe--img {
        img {
          transform-origin: 50% 50%;
          animation: 0.6s cubic-bezier(0.28, 0, 0.49, 1) 0.5s both image-intro;
        }
        &::before {
          transform-origin: 50% 100%;
          animation: 1s cubic-bezier(0.49, 0.025, 0.49, 1) 0s forwards
            background-intro;
        }
      }
    }
  }
  .bg--swipe--img {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      position: absolute;
      display: block;
      top: 0;
      inset-inline-start: 0;
      width: 100%;
      height: 100%;
      object-position: 50% 20%;
      object-fit: cover;
      animation: hidden 0s linear both;
    }
    &::before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: '';
      background: var(--primary-dark);
      animation: hidden 0s linear both;
    }
    &.primary {
      &::before {
        background: var(--primary);
      }
    }
  }

    .blog--card {
      transition: all .3s linear;
      svg {
        transition: all .3s linear;
      }
      &:hover, &:focus {
        filter: drop-shadow(10px 20px 34px rgba(0, 0, 0, 0.05));
        .blog--card--bg {
          transform: scale(1.025);
        }
        svg {
          margin-inline-start: 10px;
        }
      }
    }
    .blog--card--image {
      width: 120px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      @media ${media.mdUp} {
        width: 40%;
      }
      @media ${media.lgUp} {
        width: 230px;
      }
    }

    .blog--card--bg {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: all .3s linear !important;
    }

    #whatsapp-chat-widget #wa-widget-send-button {
      z-index: 999 !important;
    }

    video {
      &:focus {
        outline: 0;
      }
    }
    .volume--bar-1,
    .volume--bar-3 {
      height: 16px;
    }
    .modal--video.video--playing {
      .volume--bar-1 {
        animation: 1s linear 0s infinite bar1;
      }
      .volume--bar-2 {
        animation: 1s linear 0s infinite bar2;
      }
      .volume--bar-3 {
        animation: 1s linear 0s infinite bar3;
      }
    }
    @keyframes bar1 {
      0% {
        y: 9px
      }
      25% {
        y: 11px
      }
      75% {
        y: 3px
      }
    }
    @keyframes bar2 {
      0% {
        y: 0
      }
      25% {
        y: 5px
      }
      50% {
        y: 12px;
      }
      75% {
        y: 3px;
      }
    }
    @keyframes bar3 {
      0% {
        y: 6px
      }
      25% {
        y: 1px
      }
      50% {
        y: 7px;
      }
      75% {
        y: 2px;
      }
    }


    // Technology SVG
    .st0{fill:var(--accent-1);}
    .st1{fill:var(--seconardy);}
    .st2{fill:var(--primary);}
    .st3{fill:var(--primary-dark);}
    .st4{fill:var(--grey-3);}
    .st5{fill:var(--grey-1);}
    .st6{fill:none;stroke:var(--primary);stroke-width:1.4999;stroke-linecap:round;stroke-linejoin:round;}
    .st7{fill:var(--grey-6);}
    .st8{fill:var(--tertiary-light);}
    .st9{fill:#3E7061;}
    .st10{fill:var(--grey-5);}

    // Aspect ratio
    .aspect-[16/9] {
      aspect-ratio: 16 / 9;
    }

    .aspect-[16/6] {
      aspect-ratio: 16 / 6;
    }

    .aspect-[16/4] {
      aspect-ratio: 16 / 4;
    }
`;

export default GlobalStyles;
