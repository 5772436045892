import { isBrowser, getUrl } from '@publicss/utils';
import { getDistance } from 'geolib';
import styled from 'styled-components';

import { media } from '../styles/media';

export function getFacilitySlugFromUrl(url) {
  if (!url) return;

  const facId2 = url.match(/locations\/[^/]+\/[^/]+/);

  if (facId2 !== null) return facId2[0].split('locations/')[1];
  else return;
}

export const inputFocus = (event) => {
  const { id } = event.currentTarget.dataset;
  const parent = document.querySelector(`.form--element[data-id=${id}]`);
  parent.classList.add('focus');
};

export const inputType = (event) => {
  if (event.keyCode === 13) {
    event.preventDefault();
  }
  const current = event.currentTarget;
  const text = current.innerHTML;
  const { id } = current.dataset;
  const parent = document.querySelector(`.form--element[data-id=${id}]`);
  const border = document.querySelector(
    `.form--element--border[data-id=${id}]`
  );
  if (text) {
    parent.classList.remove('done');
    parent.classList.add('active');
    const width = current.clientWidth;
    border.style.width = `${width + 24}px`;
  } else {
    parent.classList.remove('active');
    border.style.width = '0px';
  }
};

export const inputReset = (event) => {
  const current = event.currentTarget;
  const text = current.innerHTML;
  const { id } = current.dataset;
  const parent = document.querySelector(`.form--element[data-id=${id}]`);
  const border = document.querySelector(
    `.form--element--border[data-id=${id}]`
  );
  if (!text) {
    parent.classList.remove('done');
    parent.classList.remove('focus');
    parent.classList.remove('active');
    border.style.width = '0px';
  } else {
    parent.classList.add('done');
  }
};

export const required = ($type, $name) => {
  const holder = document.querySelector(`.form--element[data-id="${$type}"]`);
  const label = document.querySelector(
    `.form--element[data-id="${$type}"] .form--element--label`
  );
  holder.classList.add('focus');
  holder.classList.add('error');
  if ($type === 'phone') {
    label.innerHTML = 'Please provide a valid phone number';
  } else {
    label.innerHTML = `${$name} is required`;
  }
};

export const resetLabel = ($type, $name) => {
  const holder = document.querySelector(`.form--element[data-id="${$type}"]`);
  const label = document.querySelector(
    `.form--element[data-id="${$type}"] .form--element--label`
  );
  if (holder && label) {
    holder.classList.remove('error');
    label.innerHTML = `${$name}`;
  }
};

export const currentPath = () => {
  if (!isBrowser()) {
    return;
  }
  if (window.location.pathname.includes('kitchen-growth')) {
    return window.location.pathname.split('/').slice(1)[0];
  }
  return window.location.pathname.split('/').slice(1)[1].split('-').join('_');
};

export const navigateHome = () => {
  if (isBrowser()) {
    window.location = window.location.origin;
  }
};

export const parseKitchenGrowthURL = (link) => {
  return link.split('_').join('-');
};

export function validSlug(slug) {
  if (slug !== undefined) {
    const facilitySlug = slug.match(/([\w-]+)[/]([\w-]+)/g);
    if (facilitySlug[0]?.toLowerCase() === slug.toLowerCase()) {
      return facilitySlug[0].toLowerCase();
    }
  }

  return '';
}

export function formatPhone(num) {
  const phoneNumberRaw = num?.replace(/[&-/#,+()$~%.'":*?<>{}]/g, '');

  const phoneFormatted =
    phoneNumberRaw !== undefined ? '+1 ' + phoneNumberRaw.substr(-10) : '';

  return phoneFormatted;
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function isFacilityDetailsPage() {
  const url = getUrl();
  const locationsRegex = new RegExp('locations/[a-z-]+/[a-z-]+');

  const isFacPage = locationsRegex.test(url);

  return isFacPage;
}

export function isAnyFacilityPage() {
  const url = getUrl();
  const locationsRegex = new RegExp('/locations/');

  const isFacPage = locationsRegex.test(url);

  return isFacPage;
}

export function getLeadSource(source, medium) {
  switch (source) {
    case 'google': {
      switch (medium) {
        case 'paid_search':
          return 'Inbound: Google Search';
        case 'sdc / gdn':
          return 'Inbound: Google Display';
        case 'pmax':
          return 'Inbound: Google';
        case 'video':
          return 'Inbound: Google Video';
        default:
          return 'Inbound: Other';
      }
    }
    case 'facebook': {
      switch (medium) {
        case 'paid_social':
          return 'Inbound: Facebook';
        default:
          return 'Inbound: Socials';
      }
    }
    case 'ig': {
      switch (medium) {
        case 'paid_social':
          return 'Inbound: Facebook';
        default:
          return 'Inbound: Socials';
      }
    }
    case 'linkedin': {
      switch (medium) {
        case 'paid_social':
          return 'Inbound: LinkedIn';
        default:
          return 'Inbound: Socials';
      }
    }
    case 'twitter': {
      switch (medium) {
        case 'paid_social':
          return 'Inbound: Twitter';
        default:
          return 'Inbound: Socials';
      }
    }
    case 'tiktok': {
      switch (medium) {
        case 'paid_social':
          return 'Inbound: TikTok';
        default:
          return 'Inbound: Socials';
      }
    }
    case 'bing': {
      switch (medium) {
        case 'paid_search':
          return 'Inbound: Bing Search';
        default:
          return 'Inbound: Other';
      }
    }
    case !null: {
      switch (medium) {
        case 'paid_social':
          return 'Inbound: Other';
        case 'whatsapp':
          return 'Inbound: WhatsApp';
        case 'sms':
          return 'Inbound: SMS';
        case 'affiliate':
          return 'Inbound: Affiliate';
        case 'referral':
          return 'Inbound: Referral';
        case 'tradeshow':
          return 'Inbound: Trade Show';
        case 'offline':
          return 'Inbound: Offline';
        case 'social':
          return 'Inbound: Socials';
        case 'event':
          return 'Inbound: Event';
        default:
          return 'Inbound: Website';
      }
    }
    default:
      return 'Inbound: Website';
  }
}

export function getClosestRegion(x, y, allData) {
  let shortestDistance = 41000000;
  let closestArea;

  Object.keys(allData).forEach((key) => {
    allData[key].facilities.forEach((value) => {
      const dist = getDistance(
        { latitude: x, longitude: y },
        {
          latitude: value.latitude,
          longitude: value.longitude,
        }
      );

      if (dist < shortestDistance) {
        shortestDistance = dist;
        closestArea = { facility: value, tag: key };
      }
    });
  });

  return closestArea;
}

export const Section = styled.section`
  display: block;
  width: 100%;
  text-align: center;

  h1 {
    max-width: 360px;
    margin: auto;
    text-align: left;
    margin-top: 40px !important;
    margin-bottom: 12px;
    @media ${media.mdUp} {
      margin-top: 36px !important;
    }
  }
`;

export function sortByDistance(coordinateArray, currentFacility) {
  const sortedArray = coordinateArray?.sort(function (a, b) {
    return (
      getDistance(
        {
          latitude: a.searchAddress.latitude,
          longitude: a.searchAddress.longitude,
        },
        {
          latitude: currentFacility.latitude,
          longitude: currentFacility.longitude,
        }
      ) -
      getDistance(
        {
          latitude: b.searchAddress.latitude,
          longitude: b.searchAddress.longitude,
        },
        {
          latitude: currentFacility.latitude,
          longitude: currentFacility.longitude,
        }
      )
    );
  });

  return sortedArray || [];
}
