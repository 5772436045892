import { Script } from 'gatsby';
import React from 'react';

const EmeaScripts = (): JSX.Element => {
  return (
    <>
      <Script src="https://cdn.polygraph.net/pg.js" />

      {/* <script
        async
        defer
        src="https://static.cdn.prismic.io/prismic.js?new=true&repo=entities-emea"/> */}
      {/* {data?.json_ld?.text && (
        <script type="application/ld+json">{data.json_ld.text}</script>
      )} */}
    </>
  );
};

export default EmeaScripts;
