import * as React from 'react';

import { usePageContext } from '../providers/PageContext';
import ApacScripts from './scripts/ApacScripts';
import CloudkitchensScripts from './scripts/CloudkitchensScripts';
import EmeaScripts from './scripts/EmeaScripts';
import LatamScripts from './scripts/LatamScripts';

const ScriptsResolver: React.FunctionComponent = () => {
  const { siteRegion } = usePageContext();

  switch (siteRegion) {
    case 'na':
      return <CloudkitchensScripts />;
    case 'latam':
      return <LatamScripts />;
    case 'apac':
      return <ApacScripts />;
    case 'emea':
      return <EmeaScripts />;
    default:
      return null;
  }
};

export default ScriptsResolver;
