import * as React from 'react';

import { usePageContext } from '../../providers/PageContext';
import { Form } from '../ui/Form/Form';

const regionalForms = {
  latam: React.lazy(() => import('./ContactFormLatam')),
  apac: React.lazy(() => import('./ContactFormApac')),
  emea: React.lazy(() => import('./ContactFormEmea')),
};

const ckForms = {
  blockContactSliceForm: React.lazy(() => import('./BlockContactSliceForm')),
  defaultForm: React.lazy(() => import('./AncientDivForm')),
};

interface FormResolverProps {
  caller?: 'BlockContactSlice' | 'BlockHeroFormSlice';
  color?: 'primary' | 'secondary' | 'tertiary';
  buttonText?: string;
  form_title?: string;
}

/** Handles which form to render based on site and block */
const FormResolver: React.FunctionComponent<FormResolverProps> = ({
  caller,
  color = 'primary',
  buttonText = 'Submit',
  form_title = false,
}) => {
  const pageContext = usePageContext();
  const { siteTag, siteRegion, global } = pageContext;

  if (siteTag === 'site:profood-solutions') {
    const formProps = global?.global_form_doc?.document?.data;

    return formProps ? (
      <div className="md-down:mt-12">
        <Form form_title={form_title} {...formProps} />
      </div>
    ) : null;
  }

  if (siteTag !== 'site:cloudkitchens') {
    const ContactForm = regionalForms[siteRegion];
    if (!ContactForm)
      throw new Error(
        'Failed to resolve intl contact form, check FormResolver.tsx'
      );
    return (
      <ContactForm
        textColor={caller === 'BlockHeroFormSlice' ? 'white' : 'grey'}
        siteTag={siteTag}
      />
    );
  }

  // TODO: BlockContactSliceForm and AncientDivForm should probably be merged into just one form
  const ContactForm =
    caller === 'BlockContactSlice'
      ? ckForms.blockContactSliceForm
      : ckForms.defaultForm;
  return (
    <ContactForm
      buttonText={buttonText}
      color={color}
      form_title={form_title}
    />
  );
};

export default FormResolver;
