import { Script } from 'gatsby';
import React from 'react';

const ApacScripts = (): JSX.Element => {
  return (
    <div>
      <Script src="https://cdn.polygraph.net/pg.js" />
      {/* <Script
        async
        defer
        src="https://static.cdn.prismic.io/prismic.js?new=true&repo=entities-apac"
      /> */}
    </div>
  );
};

export default ApacScripts;
